import {
  get, post, update, get2, upload, download
} from './helpers'

export function tambahRoles($data) {
  return post('/roles-add', $data)
}

export function deleteRoles($data) {
  return post('/roles-delete', $data)
}

export function getRolesById(id) {
  return get(`/roles/edit/${id}`)
}

export function updateRoles($data) {
  return post('/roles-update', $data)
}



