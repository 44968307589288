<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Tambah Data Role
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Nama</label>
           <b-form-input
              id="nama"
              autofocus
              trim
              placeholder="Masukkan Nama"
              v-model="localData.name"
            />
            <small class="mt-1" style="color: red;" v-if="error.nama">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Nama Aturan</label>
            <b-form-input
              id="nama_aturan"
              trim
              placeholder="Nama Aturan"
              v-model="localData.guard_name"
            />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button class="btn" variant="primary" @click="tambahData">Tambah</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  tambahRoles,
} from '@/connection-api/roles'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        negara_id: null,
        provinsi_id: null,
        kota_id: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
  },
  methods: {
    async tambahData()
    {
      const response = await tambahRoles(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Roles',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.localData.name = null
          this.localData.guard_name = null
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Roles',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
    },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
